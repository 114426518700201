<template>
    <layout-full-width :title="$t('my-profile')">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        {{ $t('pages.my-profile.change-password') }}
                    </div>
                    <div class="card-body">
                        <form v-if="!loading.USER_UPDATE_PASSWORD" @submit.prevent="onUpdatePassword">
                            <div class="mb-3">
                                <label class="form-label" for="password-current">
                                    {{ $t('pages.my-profile.current-password') }}
                                </label>
                                <input id="password-current"
                                       v-model="password.current"
                                       :class="{'is-invalid': $v.password.current.$error}"
                                       class="form-control"
                                       type="password">
                                <div v-if="!$v.password.current.required" class="invalid-feedback">
                                    {{ $t('validation.error.required.current-password') }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="password-new">{{
                                        $t('pages.my-profile.new-password')
                                    }}</label>
                                <input id="password-new"
                                       v-model="password.new"
                                       :class="{'is-invalid': $v.password.new.$error}"
                                       class="form-control"
                                       type="password">
                                <div v-if="!$v.password.new.valid" class="invalid-feedback">
                                    {{ $t('validation.error.invalid.password') }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="password-new-repeat">{{
                                        $t('pages.my-profile.new-password-repeat')
                                    }}</label>
                                <input id="password-new-repeat"
                                       v-model="password.newRepeat"
                                       :class="{'is-invalid': $v.password.newRepeat.$error}"
                                       class="form-control"
                                       type="password">
                                <div v-if="!$v.password.newRepeat.sameAsPassword" class="invalid-feedback">
                                    {{ $t('validation.error.invalid.password-must-match') }}
                                </div>
                            </div>

                            <div>
                                <button class="btn btn-primary" type="submit">
                                    {{ $t('actions.submit') }}
                                </button>
                            </div>
                        </form>

                        <div v-else>
                            <div class="mb-3">
                                <component-shimmer class="mb-2" height="19.2px" width="115.32px"/>
                                <component-shimmer height="33.2px" width="100%"/>
                            </div>
                            <div class="mb-3">
                                <component-shimmer class="mb-2" height="19.2px" width="98.62px"/>
                                <component-shimmer height="33.2px" width="100%"/>
                            </div>
                            <div class="mb-3">
                                <component-shimmer class="mb-2" height="19.2px" width="185.7px"/>
                                <component-shimmer height="33.2px" width="100%"/>
                            </div>
                            <component-shimmer height="33.2px" width="71.98px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../layouts/LayoutFullWidth";
import moment from 'moment';
import {countries} from "countries-list";
import getServiceErrorMessage from "../lib/get-service-error-message";
import {required, sameAs} from "vuelidate/lib/validators";
import ComponentShimmer from "../components/ComponentShimmer";

export default {
    name: "PageMyProfile",
    components: {ComponentShimmer, LayoutFullWidth},
    data() {
        return {
            moment,
            countries,
            edit: false,
            password: {
                current: '',
                new: '',
                newRepeat: ''
            }
        }
    },
    computed: {
        loading() {
            return {
                USER_UPDATE_PASSWORD: this.$store.state.loading.USER_UPDATE_PASSWORD
            }
        },
        profile() {
            return this.$store.state.USER.profile;
        }
    },
    methods: {
        onUpdatePassword() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$store.dispatch('USER/UPDATE_PASSWORD', {
                username: this.profile.username,
                password: this.password.current,
                newPassword: this.password.new
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$t('toast.success.success'),
                        message: this.$t('toast.success.password-updated')
                    });
                    this.password.new = '';
                    this.password.newRepeat = '';
                    this.$v.$reset();
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$t('toast.danger.error'),
                        message: getServiceErrorMessage(error.response.data.returnCode)
                    });
                })
        }
    },
    beforeMount() {
        this.$store.dispatch('USER/GET_PROFILE');
    },
    validations: {
        password: {
            current: {
                required
            },
            new: {
                valid: (value) => {
                    const minLength = /[^.{8,}$]/.test(value);
                    const containsUppercase = /[A-Z]/.test(value)
                    const containsLowercase = /[a-z]/.test(value)
                    const containsNumber = /[0-9]/.test(value)
                    //const containsSpecial = /[#?!@$%^&*-]/.test(value)
                    return minLength && containsUppercase && containsLowercase && containsNumber;
                }
            },
            newRepeat: {
                sameAsPassword: sameAs('new')
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
